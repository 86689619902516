/* == COLORS PALETTE == */
:root {
  --rosa: #ccacba;
  --azul4: #068D9D;
  --azul3: #0f4d5c;
  --azul2: #0b3741;
  --azul: #07252c;

  --amarillo: #f4d644;
  --amarillo2: #c1a30b;

  --grisito: #f2f2f2;
  --grisito2: #d5d9dd;
  --grisito3: #b9bfc6;
  --grisito4: #9da6af;
  --grisito5: #818c98;

  --negro1: #505962;    
  --negro2: #394046;
  --negro3: #22262a;
  --negro4: #0b0d0e;

  --headingFont:'Raleway', sans-serif;
  --textFont:  'Roboto', sans-serif;
}
/* ==================== */
html, body {
  overflow-x: hidden;
}
* {
  transition: all .5s linear;
  font-family: 'Nunito', sans-serif;
  cursor:context-menu;
}
*, body, h1, h3 {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  max-width: 100%;
}

h1, h2, h3,
h1 span {
  text-align: left;
  font-family: 'Anton Regular', sans-serif;
}

a small,
a {
  text-decoration: none;
  cursor: pointer;
}

.text-yellow {
  color: var(--amarillo);
}
/* HEADER ==================================== */
header .links {
  margin: 0 auto;
  max-width: 1360px;
}
header .links a {
  padding: 1rem 10px;
}
header .links a .contact {
  margin: 0.7rem 0px;
  padding: 0.3rem 10px;
  display: inline-block;

  border: solid 2px var(--grisito4);
  border-radius: 15px;
}
header a {
  color: var(--grisito5);
}
header h1 {
  z-index: 5;
  position: relative;
}
header #adriel-title {
  color: var(--grisito5);
  z-index: 1;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}
header #greetings {
  height: 95vh;
  width: 100%;
  overflow: hidden;
  padding-top: 35px;
}
header #adriel-avatar {
  max-width: 280px;
  width: 100%;
  border-radius: 100px 100px 100px 0px;
  z-index: 1;
  position: relative;
}
header #avatar-bg {
  max-width: 280px;
  width: 300px;
  height: 300px;
  background-color: var(--amarillo2);
  position: absolute;
  right: 8px;
  top: -12px;
  z-index: 1;
  transform: rotate(45deg);
  box-shadow: 6px 3px 10px 0px var(--grisito2);
  border-radius: 30px;
}
header #avatar-bg::after,
header #avatar-bg::before {
  content: '';
  width: 300px;
  height: 300px;
  background-color: var(--amarillo);
  position: absolute;
  z-index: 0;
  transform: rotate(323deg) skewY(13deg);
  border-radius: 70px 55px 30px 74px;
}
header #avatar-bg::before {
  background-color: #f4d64475;
  transform: rotate(20deg) skewY(10deg);
}


@media (max-width : 325px) {
  header a {
    font-size: 0.75rem;
  }
}
@media (max-width : 430px) {
  header a {
    font-size: 0.8rem;
  }
  header #avatar-bg {
    right: 0px;
  }
}
@media (min-width : 275px) {
  header #greetings h1 {
    font-size: 2rem;
  }
}
@media (min-width : 350px) {
  header #greetings h1 {
    font-size: 2.5rem;
  }
}
@media (min-width : 575px) {
  header #greetings h1 {
    margin-bottom: 5rem;
  }
}
@media (min-width : 600px) {
  header #greetings h1 {
    font-size: 4rem;
    margin-bottom: 3.25rem;
  }
}
@media (min-width : 992px) {
  header #greetings h1 {
    font-size: 5rem;
  }
  header #avatar-bg {
    max-width: 375px;
    width: 375px;
    height: 375px;
    right: -8px;
  }  
  header #avatar-bg::after {
    width: 375px;
    height: 375px;
  }
  header #avatar-bg::before {
    width: 375px;
    height: 375px;
  }
  header #adriel-avatar {
    max-width: 350px;
  }
}
/* Header > Geometric Shapes */
header .triangle:nth-of-type(2) {
  left: 85vw;
  transform: rotate(58deg);
  border-left: 60px solid #f4d6449c;
}
header .triangle:nth-of-type(2)::before {
  top: 220px;
  left: 300px;
  transform: rotate(80deg);
  border-left: 60px solid var(--amarillo);
}
.triangle {
  position: absolute;

  top: 125px;
  left: 0vh;

  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 60px solid var(--amarillo); /* change #f00 to any color for triangule */
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;

}
.triangle::before {
  content: '';
  display: block;
  position: absolute;

  top: 170px;
  left: 20vh;

  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 60px solid var(--amarillo2); /* change #f00 to any color for triangule */
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;

  transform: rotate(47deg);
}
.triangle::after {
  content: '';
  display: block;
  position: absolute;

  top: 450px;
  left: 10vh;

  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 60px solid #f4d7447a; /* change #f00 to any color for triangule */
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;

  transform: rotate(65deg) translateY(10px);
}
/*==========================================*/
main {
  margin: 7rem 0rem 0rem 0rem;
}
/*==========================================*/
/* MAIN - REPOSITORIES */
main .card-repository {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: all 0.1s linear;
}
main .card-repository:hover {
  transform: scale(1.04);
  transition: all 0.1s linear;
}
main .card-repository .text-yellow {
  right: 0px;
  border: 1px solid var(--amarillo);
  background-color: #22262a87;
}
main .card-repository-info {
  position: absolute;
  top: 0;
  left: -100%;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0.5rem 1rem;
  color: var(--grisito);
  background-color: #0b0d0eb3;
  background-position: -100%;

  transition: all 0.2s linear;
}
main .card-repository:hover .card-repository-info {
  left: 0px;
  transition: all 0.2s linear;
}
main .card-repository-info small {
  line-height: 1;
}
main .card-repository-info a {
  color: var(--amarillo);
}
main #repositories #view-repository-manual {
  bottom: -50px;
  cursor: pointer;
  z-index: 2;
}
main #repositories #view-repository-manual #next, 
main #repositories #view-repository-manual #back {
  transition: none;
}
main #repositories #view-repository-manual #next:active, 
main #repositories #view-repository-manual #back:active {
  transform: scale(0.75);
  transition: none;
}
main #repositories .triangle {
  z-index: 0;
  top: 20px;
  left: 70vh;
  border-left: 60px solid #f4d7447a; /* change #f00 to any color for triangule */
  transform: rotate(40deg);
}
main #repositories .triangle::before {

  top: 245px;
  left: 55vh;
  border-left: 60px solid var(--amarillo); /* change #f00 to any color for triangule */

  transform: rotate(47deg);
}
main #repositories .triangle::after {
  top: 450px;
  left: 10vh;

  transform: rotate(65deg) translateY(10px);
}
main .repositories-list {
  z-index: 1;
  position: relative;
}
@media (max-width : 992px) {
  main #repositories .triangle {
    top: -20px;
    left: 24vh;
  }
  main #repositories .triangle::before {
    top: 275px;
    left: 48vh;
  }
}
/*==========================================*/
/*==========================================*/
/* MAIN - knowledge */
main #knowledge {
  margin: 10rem 0rem 15rem 0rem;
}
main #knowledge img {
  width: 25px;
}
main #knowledge p {
  margin: none;
}
main .knowledge-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
main #knowledge .card-technology {
  display: inline-flex;
  align-items: center;

  background-color: var(--azul2);
  color: var(--grisito);

  padding: .75rem 2rem;
  margin: 5px 5px;
}
@media (max-width : 325px) {
  main .knowledge-list {
    transform: scale(0.85);
  }  
}
@media (max-width : 576px) {
  main #knowledge .card-technology {
    padding: 0.75rem;
    margin: 2.5px;
  }
}
/*==========================================*/
/* MAIN - about */
footer div {
  background-color: var(--amarillo2);
}
footer div a {
  color: var(--negro2);
}
main #about {
  background-color: var(--amarillo);
  padding: 5rem 0rem 7rem;

  position: relative;
  
}
main #about p {
  text-align: justify;
}
main #about a {
  color: var(--azul4);
  font-weight: bold;
}
main #about .diagonal {
  display: block;
  width: 100vw;
  height: 100px;
  background-color: var(--amarillo2);
  position: absolute;
  top: -80px;
  transform: skewX(340deg) rotate(355deg);
}
main #about .diagonal::before {
  content: '';
  display: block;
  width: 125vw;
  height: 100px;
  background-color: var(--negro4);
  position: absolute;
  left: -25px;
  top: 20px;
  transform: skewX(40deg) rotate(8deg);
}
footer img {
  filter: grayscale(1) opacity(0.9);
  width: 30px;
}
footer .githubIMG {
  filter: opacity(0.7) brightness(2)
}
footer img:hover {
  filter: none;
  transition: none;
}
/*==========================================*/
